import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { MarkDown } from './_markdown'

export const query = graphql`
  query {
    logo: directus {
      ...LogoFragment
    }
    imprint: directus {
      ...ImprintFragment
    }
  }
`

// eslint-disable-next-line import/no-default-export
export default ({ data }) => (
  <>
    <Helmet>
      <meta name="description" content="Impressum" />
    </Helmet>
    <MarkDown content={data.imprint.imprint.text} logo={data.logo.logo.logo.imageUrl} />
  </>
)
